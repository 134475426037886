// layout container home media slider
.homeMediaSlider {
  
  // mediaSlider
  .column {
    padding-left: 0px;
    padding-right: 0px;
  }
  .mediaSlider {
    margin: 0px 0;
    // title
    
    > h2 {
      display:none;
    }
    
    // description
    
    > p {
      display:none;
    }
    
    // pagination
    
    .slider-pagination {
      display: none;
    }
    .sn-media-slider .slider.top-left-paging,
    .sn-media-slider .slider.top-center-paging,
    .sn-media-slider .slider.top-right-paging
    .sn-media-slider .slider.bottom-left-paging,
    .sn-media-slider .slider.bottom-center-paging,
    .sn-media-slider .slider.bottom-right-paging {
      margin: 0;
    }
    
    // sn-media-slider
    .sn-media-slider {
      padding: 0;
    }
    // flexSlider
    .flexslider {
      .slides img {
        height: auto !important;
      }
      border:0;
      border-radius:0;
      .slides .slide .slide-overlay {
        
        padding: 5%55%8%5%;
        @media only screen and (max-width: 768px){
          padding: 5%;
          
          padding: 0;
          
        }
        
        
        
        bottom: 0;
      }
      .slideTextWrapper {
        position: relative;
        background-color: white;
        
        background-color: rgba(white, 1);
        
        padding: 15px;
        
          // accent bar
          &:before {
            content: '';
            width: 25px;
            height: 100%;
            background: $secondary-color;
            position: absolute;
            
            top: 0;
            
            @media only screen and (max-width: 768px){ display: none; }
          }
          // indicator
          &:after {
            content: '';
            height: 0;
            width: 0;
            border: 6px solid $primary-color;
            border-top-color:transparent;
            border-right-color:transparent;
            border-bottom-color:transparent;
            position: absolute;
            display: inline-block;
            @media only screen and (max-width: 768px){ display: none; }
          }
          
            &:before {
              left:  calc(100% + 5px);
            }
            &:after {
              left:  calc(100% + 5px + ((25px - 6px )/ 2));
              top: calc((100% - 12px)/2)
            }

          
          
        
        &.no-text {
          background-color: transparent;
          padding: 0;

          &:before,
          &:after { display: none; }

        }
      }
      .slide-title,
      .slide-description {
        margin: 0;
      }
      .slide-title {
        font-family: Source Sans Pro, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 42px;
        color: $secondary-color;
        text-transform: uppercase;
        text-align: inherit;
        text-shadow: none;
        line-height: 1em;
        @media screen and (max-width:1024px) {
          font-size: 21px;
        }
        @media screen and (max-width:768px) {
          font-size: 18px;
        }
      }
      .slide-description {
        font-family: Source Sans Pro, Helvetica, sans-serif;
        font-weight: 400;
        font-size: 16px;
        color: $primary-color;
        text-transform: inherit;
        text-align: inherit;
        text-shadow: none;
        line-height: inherit;
        @media screen and (max-width:1024px) {
          font-size: 12px;
        }
      }
    }
  }
}
.has-home-media-slider #siteContainer {
  padding-top:0;
}
// body.home {
//    background: url("//assets.ngin.com/site_files/16502/_site/images/custom/slider_bg.png") left top repeat-x;
//    .site-background {top:600px; margin-top:0;}
// }

html.nav-fixed body.home {
   .site-background {margin-top:0;}

}

html:not(.collapsed-mobile-nav):not(.has-sub-nav) body.home .site-background {
    margin-top: 0;
}

html.has-mobile-nav body.home {
    background:none;
    .site-background {top:0px; }
}
